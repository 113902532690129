<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Поиск"
          :value="tableParams.title"
          clearable
          @input="$emit('change-field', { stock: { title: $event } })"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-select v-model="proxySource" :items="source" label="Любой источник" item-value="uid" item-text="text" />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-select v-model="proxyCountry" :items="countries" label="Любая страна" item-value="uid" item-text="text" />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-select v-model="proxyStatus" :items="statuses" label="Любой статус" item-value="uid" item-text="text" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      countries: [
        { uid: "", text: "Любая" },
        { uid: "american", text: "США" },
        { uid: "russian", text: "Россия" },
        { uid: "hong-kong", text: "Гонконг" }
      ],
      source: [
        { uid: "", text: "Любой" },
        { uid: "admin", text: "Ручной ввод" },
        { uid: "api", text: "API" }
      ],
      statuses: [
        { uid: "", text: "Любой" },
        { uid: "published", text: "Опубликован" },
        { uid: "applied", text: "Применен" },
        { uid: "draft", text: "Неактивен" }
      ]
    };
  },
  computed: {
    proxyCountry: {
      get() {
        return this.tableParams.origin ?? this.countries[0];
      },
      set(value) {
        return this.$emit("change-field", { stock: { origin: value } });
      }
    },
    proxyStatus: {
      get() {
        return this.tableParams.status?.[0] ?? this.statuses[0];
      },
      set(value) {
        const currentValue = value === "" ? { status: [] } : { status: [value] };

        return this.$emit("change-field", currentValue);
      }
    },

    proxySource: {
      get() {
        return this.tableParams.source?.[0] ?? this.source[0];
      },
      set(value) {
        const currentValue = value === "" ? { source: [] } : { source: [value] };

        return this.$emit("change-field", currentValue);
      }
    }
  }
};
</script>
