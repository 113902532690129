<template>
  <div>
    <h2 class="mb-4">Сплиты</h2>

    <stocks-splits-filters :table-params="tableParams" @change-field="changeTableParams" />

    <data-table ref="dataTable" url="stocks/splits" :headers="headers" :table-params="tableParams">
      <template v-slot:[`item.date`]="{ item: split }">
        {{ split.date | date }}
      </template>

      <template v-slot:[`item.from`]="{ item: split }">
        {{ formateNumber(split.from) }}:{{ formateNumber(split.to) }}
      </template>

      <template v-slot:[`item.source`]="{ item: split }">
        {{ sourceMap[split.source] }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item: split }">
        {{ split.createdAt | dateTime }}
      </template>

      <template v-slot:[`item.status`]="{ item: split }">
        {{ statusMap[split.status] }}
      </template>
    </data-table>
  </div>
</template>

<script>
import { formatNumber } from "~/core/filters/numbers";

import StocksSplitsFilters from "~/components/stocks/splits/filters";

export default {
  data() {
    return {
      headers: [
        { text: "Дата сплита", value: "date", sortable: true },
        { text: "Тикер", value: "ticker", sortable: true, class: "nobr" },
        { text: "Название акции", value: "name", sortable: true },
        { text: "Пропорция", value: "from", sortable: false, class: "nobr" },
        { text: "Источник", value: "source", sortable: false, class: "nobr" },
        { text: "Дата добавления", value: "createdAt", sortable: true, class: "nobr" },
        { text: "Статус", value: "status", sortable: false, class: "nobr" }
      ],
      sourceMap: {
        admin: "Ручной ввод",
        api: "API"
      },
      statusMap: {
        published: "Опубликован",
        applied: "Применен",
        draft: "Неактивен"
      },
      tableParams: {
        stock: { title: "", origin: "" },
        status: [],
        source: []
      }
    };
  },
  methods: {
    changeTableParams(fields) {
      for (const field in fields) {
        if (field === "stock") {
          const stock = { ...this.tableParams.stock };

          for (const keyField in fields[field]) {
            stock[keyField] = fields[field][keyField];
          }

          this.tableParams[field] = stock;
        } else {
          this.tableParams[field] = fields[field];
        }
      }
    },
    formateNumber(number) {
      const options = Number.isInteger(number) ? { fractionDigits: 0 } : {};

      return formatNumber(number, options);
    }
  },
  components: {
    StocksSplitsFilters
  }
};
</script>
